import {ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[data-feather]',
  standalone: true,
})
export class FeatherIconDirective implements OnChanges {
  @Input('data-feather') name!: string;
  @Input() class!: string;
  @Input() size!: string;
  @Input() inner!: boolean;

  constructor(
    @Inject(ElementRef) private _elementRef: ElementRef,
    @Inject(ChangeDetectorRef) private _changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnChanges() {
    // Get the native element
  }
}
